/**
 * Determine whether the url is considered external compared to the base url
 * @param _url Target url
 * @param _base Base url to which to compare the target url to
 */
const isExternalUrl = (_url: string, _base?: string): boolean => {
  if (typeof window === "undefined" || !window.URL) return false;
  _base = _base || window.location.href;

  const url = new URL(_url, _base);
  const base = new URL(_base);

  return url.origin !== base.origin;
};

export default isExternalUrl;
