interface SrcSet {
  [x: string]: string;
}

export const srcset = (data: SrcSet): string => {
  return Object.keys(data)
    .map((key) => {
      return `${data[key]} ${key}`;
    })
    .join(",");
};

interface Sizes {
  [x: string]: string | null;
}

export const sizes = (data: Sizes): string => {
  return Object.keys(data)
    .map((key) => {
      if (data[key]) return `${data[key]} ${key}`;
      return key;
    })
    .join(",");
};
