import {
  ContentstackArticles,
  ContentstackCustomFieldUrl,
  ContentstackCommonPublishDetails,
  ContentstackAssets,
  Maybe,
  ContentstackCategories,
  ContentstackAuthors,
} from "../../../generated/graphql";
import { ImageAssetUrl } from "../assets/typings";
import notEmpty from "../../utils/not-empty";

export type Data = Pick<
  ContentstackArticles,
  "uid" | "title" | "date" | "description" | "youtube_link" | "external_link" | "article_type"
> & { url: Pick<ContentstackCustomFieldUrl, "url"> } & {
  category: Array<Maybe<Pick<ContentstackCategories, "title">>>;
} & { banner: Maybe<Pick<ContentstackAssets, "url">> } & {
  author: Maybe<Array<Maybe<Pick<ContentstackAuthors, "title">>>>;
};

export enum ArticleType {
  NORMAL = "Normal Article",
  PATCH_NOTES = "Patch Notes",
  EXTERNAL = "External Link",
  YOUTUBE = "Youtube",
}

export default class ContentstackArticleClass {
  data: Data;

  constructor(data: Data) {
    this.data = data;
  }

  get uid() {
    return this.data.uid;
  }

  get url() {
    switch (this.data.article_type) {
      case ArticleType.EXTERNAL:
        return this.data.external_link!;

      case ArticleType.YOUTUBE:
        return this.data.youtube_link!;

      case ArticleType.PATCH_NOTES:
      case ArticleType.NORMAL:
      default:
        return this.data.url.url;
    }
  }

  get internal() {
    return ArticleType.NORMAL === this.data.article_type || ArticleType.PATCH_NOTES === this.data.article_type;
  }

  get title() {
    return this.data.title;
  }

  get description() {
    return this.data.description;
  }

  get bannerUrl() {
    return this.data.banner ? (this.data.banner.url as ImageAssetUrl) : undefined;
  }

  get categoryTitle() {
    return this.data.category[0] ? this.data.category[0].title : undefined;
  }

  get publishDate() {
    return new Date(this.data.date);
  }

  get authors() {
    return this.data.author ? this.data.author.filter(notEmpty) : [];
  }
}
