import { css } from "styled-components";
import { fluidRange } from "polished";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../riot-locale-to-bcp47";

/**
 * Generate fluid font-size range rules with optional locale adjusted values
 *
 * Wraps polished.fluidRange and styled.css to provide declarative font size ranges
 * that can be adjusted per locale using a list of language:multiplier pairs
 *
 * fluidFontSize(
 *   24, 48,
 *   1024, 1920,
 *   {
 *     'cs-cz': 0.9
 *     'de-de': 0.8
 *     'ja-jp': 1.2
 *   }
 * )
 *
 * @param from smallest font-size value within range
 * @param to largest font-size value within range
 * @param lower width at the bottom end of the range
 * @param upper width at the top end of the range
 * @param localeMultipliers object of locale specific font-size proportional adjustments
 */

interface LocaleMultiplier {
  locale: RiotLocale;
  x: number;
}

export default function (
  from: number,
  to: number,
  lower: number,
  upper: number,
  localeMultipliers: LocaleMultiplier[] = [],
) {
  const defaultRange = fluidRange(
    {
      prop: "font-size",
      fromSize: `${from}px`,
      toSize: `${to}px`,
    },
    `${lower}px`,
    `${upper}px`,
  );

  const localeRanges = localeMultipliers.map(
    ({ locale, x }) =>
      css`
        :lang(${riotLocaleToBcp47(locale)}) {
          ${fluidRange(
            {
              prop: "font-size",
              fromSize: `${Math.round(from * x)}px`,
              toSize: `${Math.round(to * x)}px`,
            },
            `${lower}px`,
            `${upper}px`,
          )}
        }
      `,
  );

  return css`
    ${defaultRange}
    ${localeRanges}
  `;
}
