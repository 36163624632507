import React, { useCallback } from "react";
import { Link as GatsbyLink } from "gatsby-plugin-intl";
import { GatsbyLinkProps } from "gatsby";
import isExternalUrl from "../../utils/is-external-url";

export interface LinkProps {
  url: string;
  internal: boolean;
  testId?: string;
}

interface Props<TState = {}> extends LinkProps, Omit<GatsbyLinkProps<TState>, "ref" | "to" | "target" | "href"> {
  className?: string;
}

const LinkComponent = React.forwardRef<HTMLAnchorElement, Props>(({ url, internal, testId, children, ...otherProps }, ref) => {
  const onClickHandler = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (otherProps.onClick) otherProps.onClick(e);
      if (e.defaultPrevented) return;
      if (!isExternalUrl(url)) return;

      // open external urls in a new window
      e.preventDefault();
      window.open(url, "_blank");
    },
    [url, otherProps.onClick],
  );

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={url} ref={ref as any} {...otherProps} data-testid={testId}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a {...otherProps} ref={ref} href={url} onClick={onClickHandler} data-testid={testId}>
      {children}
    </a>
  );
});

LinkComponent.displayName = "Link";
export default LinkComponent;

export const isInternalUrl = (url: string): boolean => {
  return false;
};
