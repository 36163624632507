import styled from "styled-components";

export const NoScriptImg = styled.img``;

export const Img = styled(NoScriptImg)`
  /* hide regular image when javascript is off */
  html:not(.js) & {
    display: none !important;
  }

  /* hide image without src set, otherwise an outline appears */
  &:not([src]):not([srcset]) {
    visibility: hidden;
  }
`;
