import React, { useRef, useEffect } from "react";
import * as Styled from "./style";

import { useObjectFit, ObjectFitType } from "../../utils/hooks/use-object-fit";

export interface ComponentImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "onLoad" | "onError"> {
  lazy?: boolean;
  src: string;
  objectFit?: ObjectFitType;
  objectPosition?: string;
  style?: React.CSSProperties;
  onLoad?: (element: HTMLImageElement) => void;
  onError?: (element: HTMLImageElement) => void;
  testId?: string;
}

const ComponentImage: React.FC<ComponentImageProps> = ({
  lazy = false,
  src,
  objectFit,
  objectPosition,
  srcSet,
  style,
  onLoad,
  onError,
  testId,
  ...otherProps
}) => {
  // use state to remember once image has been set to load and not to unload if "lazy" prop changes
  const [load, setLoad] = React.useState<boolean>(!lazy);
  if (!lazy && !load) setLoad(true);

  const imageRef = useRef<HTMLImageElement>(null);
  const [imageStyle] = useObjectFit(imageRef, { objectFit, objectPosition }, [src, srcSet]);
  const combinedStyle = { ...style, ...imageStyle };

  /**
   * Because of SSR, React can be hydrated after the image is already loaded.
   * Therefore, the onLoad/onError methods will not be triggered automatically.
   * https://github.com/facebook/react/issues/12641
   * https://stackoverflow.com/questions/39777833/image-onload-event-in-isomorphic-universal-react-register-event-after-image-is/39778416
   */
  useEffect(() => {
    const image = imageRef.current;

    if (!image) return;

    if (image.complete) {
      // image already downloaded
      if (image.naturalWidth > 0) {
        if (onLoad) onLoad(image);
      } else {
        if (onError) onError(image);
      }
      return;
    } else {
      // wait for image download to complete
      const loadFn = onLoad ? () => onLoad(image) : null;
      if (loadFn) image.addEventListener("load", loadFn);

      const errorFn = onError ? () => onError(image) : null;
      if (errorFn) image.addEventListener("error", errorFn);

      return () => {
        if (loadFn) image.removeEventListener("load", loadFn);
        if (errorFn) image.removeEventListener("load", errorFn);
      };
    }
  }, [imageRef]);

  return (
    <React.Fragment>
      {/* javascript enabled: dynamically load image src as needed */}
      <Styled.Img
        ref={imageRef}
        src={load ? src : undefined}
        srcSet={load ? srcSet : undefined}
        style={combinedStyle}
        data-testid={testId}
        {...otherProps}
      />

      {/* javascript disabled: show search engine friendly image with sources set as expected */}
      <noscript>
        <Styled.NoScriptImg src={src} srcSet={srcSet} style={combinedStyle} {...otherProps} />
      </noscript>
    </React.Fragment>
  );
};

export default ComponentImage;
export { srcset, sizes } from "./helper";
